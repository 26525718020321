import { extendTheme } from "@chakra-ui/react"
import React from "react"
import { UserProvider } from "./src/context/userContext"

export const wrapRootElement = ({ element }) => {
  const theme = extendTheme({
    fonts: {
      heading:
        " 'ProximaNovaA-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif",
      body:
        " 'ProximaNovaA-Light', 'Helvetica Neue', Helvetica, Arial, sans-serif",
    },
  })

  return <UserProvider>{element}</UserProvider>
}
