import React, { createContext, useEffect, useReducer } from "react"
import { SearchModel } from "../interfaces/kiosk"
import { BasketUnit } from "./checkoutContext"

export interface State {
  basket: BasketUnit[]
  basketHash?: string
  coordinates?: { lat: number; lon: number }
  search?: SearchModel
  currentKioskId?: string
  jwt?: string
  facebookLogin?: any
  gpsRequested?: boolean
  gpsUpdated?: number
  likes: string[]
}

const reducer = (state: State, newState: Partial<State>): State => {
  return { ...state, ...newState }
}
const UserContext = createContext<{
  state: State | undefined
  dispatch: React.Dispatch<Partial<State>>
}>({} as any)

const { Provider } = UserContext

const StateProvider = ({ children }: any) => {
  const [state, dispatch] = useReducer(reducer, {
    basket: [],
    likes: localStorage?.getItem("likes")?.split(";") ?? [],
  })

  useEffect(() => {
    const jwt =
      new URL(window.location.href).searchParams.get("jwt") ??
      localStorage.getItem("jwt") ??
      undefined
    const basketString = localStorage.getItem("basket")
    const basket = basketString ? JSON.parse(basketString) : []

    if ((jwt || basket.length) && !state?.jwt) {
      dispatch({ ...state, jwt, basket })
    }
  }, [])

  const value = { state, dispatch }

  return <Provider value={value}>{children}</Provider>
}

export const UserProvider = StateProvider
export const UserConsumer = UserContext.Consumer

export default UserContext
